import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Link from "@mui/material/Link";
import Header from "../components/Header";
import Footer from "../components/Footer";

function Refund() {
  return (
    <React.Fragment>
      <Box>
        <Header />
      </Box>
      <Box>
        <Box
          mt={14}
          pl={{ xs: 4, sm: 5, md: 18, lg: 18 }}
          pr={{ xs: 4, sm: 5, md: 18, lg: 18 }}
          pt={{ xs: 4, sm: 5, md: 5, lg: 5 }}
          pb={{ xs: 4, sm: 5, md: 18, lg: 18 }}
        >
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            textAlign={"center"}
            fontWeight={"bold"}
          >
            REFUND AND CANCELLATION POLICY
          </Typography>
          <Typography variant="body1" paragraph>
            The terms "We" / "Us" / "Our" Company” individually and collectively
            refer to VUCAWARE EMERGING TECHNOLOGIES PRIVATE LIMITED and the
            terms "You" /"Your" / "Yourself" refer to the Users.
          </Typography>
          <Typography variant="body1" paragraph>
            This REFUND and CANCELLATION POLICY is an electronic record in the
            form of an electronic contract formed under the Information
            Technology Act, 2000 and the rules made thereunder and the amended
            provisions pertaining to electronic documents / records in various
            statutes as amended by the information Technology Act, 2000. This
            REFUND and CANCELLATION POLICY does not require any physical,
            electronic or digital signature.
          </Typography>
          <Typography variant="body1" paragraph>
            This REFUND and CANCELLATION POLICY is a legally binding document
            between You and Us. The terms of this REFUND and CANCELLATION POLICY
            will be effective upon Your acceptance of the same (directly or
            indirectly in electronic form, by clicking on the I accept tab or by
            use of the website or by other means) and will govern the
            relationship between You and Us for Your use of the website &nbsp;
            {
              <Link
                sx={{ color: "blue" }}
                href="https://www.vucaware.com"
                target="_blank"
              >
                https://www.vucaware.com /
              </Link>
            }{" "}
            or &nbsp;
            {
              <Link
                sx={{ color: "blue" }}
                href="https://techworth.trueselfy.com/"
                target="_blank"
              >
                https://techworth.trueselfy.com/
              </Link>
            }{" "}
            (“Website”).
          </Typography>
          <Typography variant="body1" paragraph>
            Please read this REFUND and CANCELLATION POLICY carefully before
            using the Website and purchasing any study or course material or
            such other services as a digital product or hard copy of such
            product from the Website (to be referred to as ‘the Products’), You
            indicate that You understand, agree and consent to this REFUND and
            CANCELLATION POLICY. If You do not agree with the terms of this
            REFUND and CANCELLATION POLICY, please do not purchase the Products
            from the Website.
          </Typography>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            fontWeight={"bold"}
            pt={3}
          >
            REFUND TERMS:
          </Typography>
          <Typography variant="body1" paragraph>
            The Products (digital products) which are downloadable, on license
            or accessible on a subscription basis or otherwise by such payment
            method as may be provided in the Website and upon such due payments
            as provided shall not be REFUNDABLE once the Product is downloaded,
            licensed or accessed. Every Product upon purchase shall be
            considered as downloaded, licensed or accessed by You unless proved
            otherwise.
          </Typography>
          <Typography variant="body1" paragraph>
            Such Products can only be refunded in the proven and established
            event of downloading error and/or corruption of Product etc. in the
            first instance.
          </Typography>
          <Typography variant="body1" paragraph>
            The Products in hard copy once received by the User shall not be
            refunded. The refund can be claimed only in the proven and
            established instance of non-delivery or wrong delivery of the
            Products.
          </Typography>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            fontWeight={"bold"}
            pt={3}
          >
            REFUND PROCEDURE:
          </Typography>
          <Typography variant="body1" paragraph>
            The User shall send a request for refund ("Refund Request") of the
            payment received by the Company within five working days from the
            date of receipt of Products to the Grievance Officer (as defined
            below). The User must provide following details in the Refund
            Request:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                The details of the transaction including transaction number,
                invoice, Product detail, date of purchase, proof of payment etc.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                A statement explaining how the User is entitled to Refund of
                payments with reasons of demand of refund, together with all
                documentary evidence in support of contesting the Refund.
              </Typography>
            </li>
          </ul>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            fontWeight={"bold"}
            pt={3}
          >
            REFUND AND SHIPPING:
          </Typography>
          <Typography variant="body1" paragraph>
            In case we are satisfied with the details of the refund request,
            then we may, at our sole discretion, decide to either return such
            amount of payment after deduction of taxes, if any, or provide a
            link to download the product again or send you a replacement
            (digitally or physically) of the product with a new similar product.
            If you are approved for a refund, it will be processed within 7
            working/business days. You will be responsible for paying for your
            own shipping costs for returning the product. Shipping costs are not
            refundable. If you proved to be entitled to a refund, we will deduct
            the cost of return shipment from your approved amount of refund
          </Typography>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            fontWeight={"bold"}
            pt={3}
          >
            CANCELLATION POLICY:
          </Typography>
          <Typography variant="body1" paragraph>
            There is no cancellation policy available at the time.
          </Typography>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            fontWeight={"bold"}
            pt={3}
          >
            CUSTOMER CANCELLATION
          </Typography>
          <Typography variant="body1" paragraph>
            Orders once billed cannot be cancelled.
          </Typography>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            fontWeight={"bold"}
            pt={3}
          >
            OTHER CANCELLATIONS
          </Typography>
          <Typography variant="body1" paragraph>
            Vucaware also reserves the right to cancel any orders at any time.
          </Typography>

          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            fontWeight={"bold"}
            pt={3}
          >
            GRIEVANCE REDRESSAL:
          </Typography>
          <Typography variant="body1" paragraph>
            Any Refund Request shall be addressed to the designated officer
            (‘Grievance Officer’) as mentioned below through registered email to
            below Grievance Officer.
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Email:</b> support@vucaware.com
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Email subject:</b> “Refund Request for emailID : &lt;email&gt;”
          </Typography>
          <Typography variant="body1" paragraph>
            <b>Do not forget to include:</b>
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                Payment transaction reference
              </Typography>
            </li>
            <li>
              <Typography variant="body1">Payment Date</Typography>
            </li>
            <li>
              <Typography variant="body1">ResultID</Typography>
            </li>
            <li>
              <Typography variant="body1">Name of the Person</Typography>
            </li>
            <li>
              <Typography variant="body1">Payment Amount</Typography>
            </li>
          </ul>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </React.Fragment>
  );
}

export default Refund;
